<template>
  <div class="admin appointments">

    <header class="admin-header cf">
      <h1>{{ $t('admin.employees') }}</h1>
      <div class="meta">
        <a @click="modal_add = true" class="button button-red">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus"
            role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor"
              d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z">
            </path>
          </svg>
          <span>Mitarbeiter hinzufügen</span>
        </a>
      </div>
    </header>

    <div v-if="loading" class="loading-wrap">
      <span class="loading-spinner"></span>
    </div>
    <div v-else>

      <div class="search-wrap">
        <input v-model="search_phrase" v-on:input="search_handler" type="text" class="form-search">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
      </div>

      <div v-if="search_loading" class="loading-wrap">
        <span class="loading-spinner"></span>
      </div>
      <div v-else>
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Rolle</th>
              <th>Standort</th>
              <th>Schalter</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" v-bind:key="user.id">
              <td width="20%">
                {{ user.last_name }}, {{ user.first_name }}
              </td>
              <td width="20%">
                <span v-if="user.role == 'staff'">Mitarbeiter</span>
                <span v-if="user.role == 'administrator'">Administrator</span>
              </td>
              <td width="25%">
                <span v-for="location in user.locations" v-bind:key="location.id"
                  style="display:inline-block;margin-right:10px;background: #F6F6F6;border: 1px solid #D0D0D0;border-radius: 4px;font-size:12px;padding:5px 8px">{{
                    location.name }}</span>
              </td>
              <td width="25%">
                <span v-for="counter in user.counters" v-bind:key="counter.id"
                  style="display:inline-block;margin-right:10px;background: #F6F6F6;border: 1px solid #D0D0D0;border-radius: 4px;font-size:12px;padding:5px 8px">{{
                    counter.name }}</span>
              </td>
              <td width="10%" class="actions">
                <router-link :to="'/admin/dashboard'">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                  </svg>
                </router-link>
                <router-link :to="'/admin/users/' + user.id">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                  </svg>
                </router-link>
                <!--<a @click="delete_user(user)">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                </a>-->
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_add">
      <svg @click="modal_add = false" aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle"
        class="modal-close svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512">
        <path fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z">
        </path>
      </svg>
      <div class="modal-head">
        <h3>Mitarbeiter hinzufügen</h3>
      </div>
      <div class="modal-body">

        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="form-wrap">
              <label for="first_name" class="form-label">Vorname</label>
              <input v-model="user.first_name" class="form-input input-grey" type="text" name="first_name"
                id="first_name">
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap">
              <label for="last_name" class="form-label">Nachname</label>
              <input v-model="user.last_name" class="form-input input-grey" type="text" name="last_name" id="last_name">
            </div>
          </div>
        </div>

        <div class="form-wrap">
          <label for="email" class="form-label">E-Mail</label>
          <input v-model="user.email" class="form-input input-grey" type="text" name="email" id="email">
        </div>

        <div class="form-wrap">
          <label for="password" class="form-label">Passwort</label>
          <input v-model="user.password" class="form-input input-grey" type="password" name="password" id="password">
        </div>

        <div class="form-wrap select-wrap">
          <label for="role">Rolle</label>
          <select v-model="user.role" class="form-input">
            <option key="staff" value="staff">Mitarbeiter</option>
            <option key="administrator" value="administrator">Administrator</option>
          </select>
        </div>

      </div>
      <div class="modal-meta">
        <a @click="create_user" class="button button-red button-100">Mitarbeiter erstellen ›</a>
      </div>
    </vue-final-modal>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_edit">
      <svg @click="modal_edit = false" aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle"
        class="modal-close svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512">
        <path fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z">
        </path>
      </svg>
      <div class="modal-head">
        <h3>Mitarbeiter bearbeiten</h3>
      </div>
      <div class="modal-body">

        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="form-wrap">
              <label for="first_name" class="form-label">Vorname</label>
              <input v-model="selected_user.first_name" class="form-input input-grey" type="text" name="first_name"
                id="first_name">
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap">
              <label for="last_name" class="form-label">Nachname</label>
              <input v-model="selected_user.last_name" class="form-input input-grey" type="text" name="last_name"
                id="last_name">
            </div>
          </div>
        </div>

        <div class="form-wrap">
          <label for="email" class="form-label">E-Mail</label>
          <input v-model="selected_user.email" class="form-input input-grey" type="text" name="email" id="email">
        </div>

        <div class="form-wrap">
          <label for="password" class="form-label">Passwort</label>
          <input v-model="selected_user.password" class="form-input input-grey" type="password" name="password"
            id="password">
        </div>

        <div class="form-wrap select-wrap">
          <label for="role">Rolle</label>
          <select v-model="selected_user.role" class="form-input">
            <option key="staff" value="staff">Mitarbeiter</option>
            <option key="administrator" value="administrator">Administrator</option>
          </select>
        </div>


      </div>
      <div class="modal-meta">
        <a @click="update_user" class="button button-red button-100">Mitarbeiter bearbeiten ›</a>
      </div>
    </vue-final-modal>

  </div>
</template>

<script>
export default {
  name: 'admin_categories',
  data() {
    return {
      loading: true,
      modal_add: false,
      modal_edit: false,
      users: [],
      user: {},
      selected_user: {},
      search_loading: false,
      search_phrase: "",
      searching: false,
    }
  },
  methods: {
    async get_data() {
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/users', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.users = response.data.users;
        })
      this.loading = false;
    },
    delete_user(user) {
      if (confirm("Wirklich löschen?")) {
        this.$http.delete(process.env.VUE_APP_BASE_API + '/admin/users/' + user.id, { headers: { Authorization: this.$store.getters.get_token } })
          .then(() => {
            this.get_data();
            this.$notify({
              title: "Erfolgreich gelöscht.",
              type: "success"
            });
          })
      }
    },
    edit_user(user) {
      this.selected_user = user;
      this.modal_edit = true;
    },
    create_user() {
      this.$http.post(process.env.VUE_APP_BASE_API + '/admin/users', this.user, { headers: { Authorization: this.$store.getters.get_token } })
        .then(() => {
          this.get_data();
          this.modal_add = false;
          this.$notify({
            title: "Erfolgreich erstellt.",
            type: "success"
          });
        })
        .catch(error => {
          error.response.data.errors.forEach(value => {
            this.$notify({
              title: value,
              type: "error"
            });
          });
        });
    },
    update_user() {
      this.$http.patch(process.env.VUE_APP_BASE_API + '/admin/users/' + this.selected_user.id, this.selected_user, { headers: { Authorization: this.$store.getters.get_token } })
        .then(() => {
          this.get_data();
          this.modal_edit = false;
          this.$notify({
            title: "Erfolgreich gespeichert.",
            type: "success"
          });
        })
        .catch(error => {
          error.response.data.errors.forEach(value => {
            this.$notify({
              title: value,
              type: "error"
            });
          });
        });
    },
    search_handler() {
      if (this.searching == false) {
        this.search_loading = true;
        this.searching = true;
        this.$http.get(process.env.VUE_APP_BASE_API + '/admin/users', {
          params: {
            search_phrase: this.search_phrase
          },
          headers: { Authorization: this.$store.getters.get_token }
        })
          .then(response => {
            this.users = response.data.users;
            this.search_loading = false;
            this.searching = false;
          })
      }
    },
  },
  mounted() {
    this.get_data();
  }
}
</script>
